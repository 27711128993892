<template>
  <div>
    <v-row no-gutters justify="center" class="mt-5">
      <v-col cols="4" class="text-center">
        <h2>{{$vuetify.lang.t('$vuetify.login.willkommen')}}</h2>
        <h1 v-html="$vuetify.lang.t('$vuetify.login.portal')"></h1>
        <br/>
        <v-form ref="login-form" @submit.prevent="submit" lazy-validation>
          <v-card>
            <v-card-title>
              {{$vuetify.lang.t('$vuetify.optIn.passwort_vergeben')}}
            </v-card-title>
            <v-card-text class="pb-0">
              <p class="text-left">{{$vuetify.lang.t('$vuetify.optIn.passwort_vergeben_text')}}</p>
              <v-text-field
                  :label="$vuetify.lang.t('$vuetify.optIn.passwort')"
                  type="password"
                  v-model="password"
                  :rules="passwordRules"
                  outlined
                  dense
                  required
              ></v-text-field>
              <v-text-field
                  :label="$vuetify.lang.t('$vuetify.optIn.passwort_wiederholen')"
                  type="password"
                  v-model="passwordRepeat"
                  :rules="passwordRepeatRules"
                  outlined
                  dense
                  required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn block depressed color="primary" type="submit" :loading="submitting">
                {{$vuetify.lang.t('$vuetify.optIn.passwort_speichern_button')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import client from '@/utils/client';

export default {
  data: () => ({
    submitting: false,
    password: '',
    passwordRepeat: '',
    passwordRules: [],
    passwordRepeatRules: [],
    token: null,
  }),
  mounted() {
    this.token = this.$route.params.token;
    client.get('/login/mieter/optin/' + this.token).then().catch(() => {
      this.$store.commit('alert/set', {show: true, title: this.$vuetify.lang.t('$vuetify.optIn.token_abgelaufen_titel'), message: this.$vuetify.lang.t('$vuetify.optIn.token_abgelaufen_text'), callback: () => {
          this.$router.push('/login');
      }});
    });
    this.passwordRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.optIn.passwort_erforderlich'),
      v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!^°§$%&/()|[\]=?+*#\-_.:,;<>€@{ }'ß])[A-Za-z\d!^°§$%&/()|[\]=?+*#\-_.:,;<>€@{ }'ß]{8,}$/.test(v) || this.$vuetify.lang.t('$vuetify.optIn.passwort_ungueltig')
    ];
    this.passwordRepeatRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.optIn.passwort_wiederholen_erforderlich'),
      v => v === this.password || this.$vuetify.lang.t('$vuetify.optIn.passwort_unterschied'),
    ];
  },
  methods: {
    submit() {
      if(this.$refs['login-form'].validate()) {
        this.submitting = true;
        client.post('/login/mieter/optin/' + this.token, {passwort: this.password}).then(response => {
          this.submitting = false;
          if(response.data.success) {
            this.$store.commit('alert/set', {show: true, title: this.$vuetify.lang.t('$vuetify.optIn.passwort_gespeichert_titel'), message: this.$vuetify.lang.t('$vuetify.optIn.passwort_gespeichert_nachricht'), callback: () => {
                this.$router.push('/login');
            }});
          } else {
            this.$store.commit('snackbar/set', {show: true, color: 'error', message: this.$vuetify.lang.t('$vuetify.optIn.undefinedError')});
          }
        }).catch(() => {
          this.submitting = false;
        });
      }
    }
  }
}
</script>
